import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from "./views/Dashboard";
import Default from "@/components/layouts/Default";
import Blank from "@/components/layouts/Blank.vue";
import Login from "@/views/auth/Login.vue";
import Organization from "@/views/organization/Organization.vue";
import notify from "@/utils/notify";
import Users from "@/views/user/Users.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import UpdatePassword from "@/views/auth/UpdatePassword.vue";
import UserProfile from "@/views/user/UserProfile.vue";
import ChangePassword from "@/views/user/ChangePassword.vue";
import AllOrganizations from "@/views/organization/AllOrganizations.vue";
import VerifyOtp from "@/views/auth/VerifyOtp.vue";
import {decrypt} from "@/utils/crypto";
import SignUp from "@/views/auth/SignUp.vue";
import AddOrganization from "@/views/organization/AddOrganization.vue";
import Stores from "@/views/store/Stores.vue";
import Products from "@/views/product/Products.vue";
import AddProduct from "@/views/product/AddProduct.vue";
import ProductDetails from "@/views/product/ProductDetails.vue";
import EditProduct from "@/views/product/EditProduct.vue";
import Categories from "@/views/category/Categories.vue";
import Units from "@/views/unit/Units.vue";
import ProductsByCategory from "@/views/category/ProductsByCategory.vue";
import Suppliers from "@/views/supplier/Suppliers.vue";
import AddSupplier from "@/views/supplier/AddSupplier.vue";
import SupplierDetails from "@/views/supplier/SupplierDetails.vue";
import EditSupplier from "@/views/supplier/EditSupplier.vue";
import ProductsBySupplier from "@/views/supplier/ProductsBySupplier.vue";
import SelectStore from "@/views/store/SelectStore.vue";
import Transfers from "@/views/transfer/Transfers.vue";
import TransferDetails from "@/views/transfer/TransferDetails.vue";
import Stock from "@/views/store/Stock.vue";
import StockTakes from "@/views/stock-take/StockTakes.vue";
import StockTakeDetails from "@/views/stock-take/StockTakeDetails.vue";
import StoreOrders from "@/views/store-order/StoreOrders.vue";
import StoreOrderDetails from "@/views/store-order/StoreOrderDetails.vue";
import Orders from "@/views/order/Orders.vue";
import OrderDetails from "@/views/order/OrderDetails.vue";
import AllStoreOrders from "@/views/order/AllStoreOrders.vue";
import Pos from "@/views/pos/Pos.vue";
import Sales from "@/views/sale/Sales.vue";
import SaleDetails from "@/views/sale/SaleDetails.vue";
import StoreSales from "@/views/sale/StoreSales.vue";
import StoreAnalytics from "@/views/analytics/StoreAnalytics.vue";
import Logs from "@/views/account/Logs.vue";
import Purchases from "@/views/purchase/Purchases.vue";
import PurchaseDetails from "@/views/purchase/PurchaseDetails.vue";
import Transactions from "@/views/transaction/Transactions.vue";


const router = createRouter({
    history: createWebHistory(),
    routes:[
        { path: '/', redirect: '/dashboard' },
        {
            path: '/',
            component: Default,
            children: [
                {
                    path:'/dashboard',
                    component:Dashboard,
                },
                {
                    path:'/organization',
                    component:Organization,
                },
                {
                    path:'/stores',
                    component:Stores,
                },
                {
                    path:'/products',
                    component:Products,
                },
                {
                    path:'/add-product',
                    component:AddProduct,
                },
                {
                    path:'/product/:id',
                    component:ProductDetails,
                },
                {
                    path:'/edit-product/:id',
                    component:EditProduct,
                },
                {
                    path:'/categories',
                    component:Categories,
                },
                {
                    path:'/category/:id/products',
                    component:ProductsByCategory,
                },
                {
                    path:'/units',
                    component:Units,
                },
                {
                    path:'/suppliers',
                    component:Suppliers,
                },
                {
                    path:'/add-supplier',
                    component:AddSupplier,
                },
                {
                    path:'/supplier/:id',
                    component:SupplierDetails,
                },
                {
                    path:'/edit-supplier/:id',
                    component:EditSupplier,
                },
                {
                    path:'/supplier/:id/products',
                    component:ProductsBySupplier,
                },
                {
                    path:'/sales',
                    component:Sales,
                },
                {
                    path:'/store-sales',
                    component:StoreSales,
                },
                {
                    path:'/sale/:id',
                    component:SaleDetails,
                },
                {
                    path:'/all-store-orders',
                    component:AllStoreOrders,
                },
                {
                    path:'/orders',
                    component:Orders,
                },
                {
                    path:'/purchases',
                    component:Purchases,
                },
                {
                    path:'/transactions',
                    component:Transactions,
                },
                {
                    path:'/purchase/:id',
                    component:PurchaseDetails,
                },
                {
                    path:'/order/:id',
                    component:OrderDetails,
                },
                {
                    path:'/transfers',
                    component:Transfers,
                },
                {
                    path:'/transfer/:id',
                    component:TransferDetails,
                },
                {
                    path:'/store-products',
                    component:Stock,
                },
                {
                    path:'/stock-take',
                    component:StockTakes,
                },
                {
                    path:'/stock-take/:id',
                    component:StockTakeDetails,
                },
                {
                    path:'/store-orders',
                    component:StoreOrders,
                },
                {
                    path:'/store-order/:id',
                    component:StoreOrderDetails,
                },
                {
                    path:'/store-analytics',
                    component:StoreAnalytics,
                },
                {
                    path:'/logs',
                    component:Logs,
                },
                {
                    path:'/users',
                    component:Users,
                },
                {
                    path:'/profile',
                    component:UserProfile,
                },
                {
                    path:'/change-password',
                    component:ChangePassword,
                },
                {
                    path:'/organizations',
                    component:AllOrganizations,
                },
            ]
        },
        {
            path: '/',
            component: Blank,
            children: [
                {
                    path:'/login',
                    component:Login,
                },
                {
                    path:'/signup',
                    component:SignUp,
                },
                {
                    path:'/verify-otp',
                    component:VerifyOtp
                },
                {
                    path:'/create-organization',
                    component:AddOrganization
                },
                {
                    path:'/select-store',
                    component:SelectStore
                },
                {
                    path:'/pos',
                    component:Pos,
                },
                {
                    path:'/forgot-password',
                    component:ForgotPassword,
                },
                {
                    path:'/update-password/:email',
                    component:UpdatePassword,
                },
            ],
        },
    ],
});

// User access control
let unauthenticated = ["login", "signup", "verify-otp", "forgot-password", "update-password", "verifyPayslip"]
let superAdmin = ["organizations", "settings"]
let expiredSubscription = ["dashboard", "upgrade", "paymentSuccess", "select-store"]
let neededFiles = ["dashboard", "create-organization", "change-password", "select-store"]
let emailVerified = ["dashboard", "change-password", "select-store"];
let selectStore = ["select-store"];

router.beforeEach(async (to, from, next) => {
    let routeTo = to.path.split('/', 2)[1]
    const token = decrypt(sessionStorage.getItem('access_token'))
    if (!unauthenticated.includes(routeTo)) {
        if (token) {
            let profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
            let store = sessionStorage.getItem('store')
            let subscription = decrypt(sessionStorage.getItem('subscriptionStatus'))
            let isSuperAdmin = profile.role === 'super_admin'
            if (profile.organization_id === null && routeTo !== 'create-organization') {
                notify.alert.info('Please create your organization first.')
                next('/create-organization')
            }
            let orgData = sessionStorage.getItem('organization')
            let organization = orgData !== null ? JSON.parse(decrypt(orgData)) : {files: []}
            if (store === null && !selectStore.includes(routeTo)) {
                notify.alert.info('Please select a store to continue.')
                next('/select-store')
            } else if (profile.email_verified_at === null && !emailVerified.includes(routeTo)) {
                notify.alert.info('First time login. Please change your password.')
                next('/change-password')
            } else if (organization.files.length < 2 && !neededFiles.includes(routeTo)) {
                notify.alert.warning('Please upload your organization files first.')
                next('/dashboard')
            } else if (subscription === '' && !expiredSubscription.includes(routeTo) || subscription === 'expired' && !expiredSubscription.includes(routeTo)) {
                notify.alert.warning('You have an expired subscription. Please renew your subscription.')
                next('/dashboard')
            } else if (superAdmin.includes(routeTo) && !isSuperAdmin) {
                notify.alert.warning('You do not have permission to access this page.')
                next('/dashboard')
            }
            next()
        } else {
            next('/login')
        }
    } else {

        if (token) {
            notify.alert.info('You have an active session. Please logout first.')
            next('/')
        }
        next()
    }
})

export default router;
